<template>
  <div class="content" :style="{'backgroundImage':'url(' + bgc + ')'}">
    <div class="contentTopImg">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-01/19/yuelvhuiaOSkEjwuf11606821425.png"
        alt=""
        @click="goBuyFood"
      >
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-01/19/yuelvhuiKhr3Ss0Nxl1606821581.png"
        alt=""
        @click="goDelivery"
      >
    </div>
    <div class="shearImg">
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-01/19/yuelvhuiIi80yJM0Pp1606821200.png"
        alt=""
        @click="goShear"
      >
    </div>
    <div class="RuleDescription">
      <p>{{ RuleDescriptionTitle }}</p>
      <p v-for="(item,index) in RuleDescriptionList" :key="index">{{ item }}</p>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Vue from 'vue'
import { getElmData } from '@/services/comeOn.js'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Toast)
export default {
  name: 'Index',
  data() {
    return {
      bgc: '', // 背景图
      RuleDescriptionTitle: '', // 规则说明title
      RuleDescriptionList: [], // 规则说明
      goBuyFoodUrl: '', // 买菜链接
      goDeliveryUrl: '', // 外卖链接
      share: {}// 分享内容
    }
  },
  created() {

  },
  mounted() {
    this.getUrl()
    // 分享配置
    this.configShare()
  },
  methods: {
    // 跳转url
    getUrl() {
      getElmData({
        mid: localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          this.bgc = res.data.bg// 背景图
          this.RuleDescriptionTitle = res.data.sm.title// 规则说明title
          this.RuleDescriptionList = res.data.sm.content// 规则说明
          this.goBuyFoodUrl = res.data.elmRetail.uri// 买菜
          this.goDeliveryUrl = res.data.elmCps.uri// 外卖
          this.share = res.data.share// 分享内容
        } else {
          Toast(res.msg)
        }
      })
    },
    // 买菜
    goBuyFood() {
      window.location.replace(this.goBuyFoodUrl)
    },
    // 外卖
    goDelivery() {
      window.location.replace(this.goDeliveryUrl)
    },
    // 分享
    goShear() {
      Toast('请点击右上角进行分享')
    },
    // 分享配置
    configShare() {
      const shareInfo = {
        title: this.share.title, // 分享标题
        desc: this.share.desc, // 分享描述
        link: 'https://web.yuetao.vip/hungry?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.share.poster // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  position: absolute;
  /*background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-01/18/yuelvhuikDMx3WpQCy1606819622.png");*/
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .contentTopImg {
    width: 90%;
    display: flex;
    margin: 350px auto 10px auto;

    img {
      width: 45%;
      height: 45px;
    }

    img:nth-child(2) {
      margin-left: auto;
    }
  }

  .shearImg {
    width: 90%;
    margin: 0 auto;
  }

  .RuleDescription {
    width: 95%;
    text-align: left;
    margin: 0 auto;
    background-color: #0451AF;
    border-radius: 3px;
    font-size: 12px;
    color: #fff;
    padding: 15px 10px;
    line-height: 20px;
  }
}
</style>
